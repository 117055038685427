// Breakpoint 
@include media-breakpoint-up(xxl) {
    .page-top {
    
        // ALS Wrapper
        #als-wrapper {
            position: relative;
            height: 100%;
            align-items: center;
    
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform:translate(-50%, -50%);
                width: 100%;
                height: 100%;
                max-width: 900px;
            }
        }
    }
    
    .page-bottom {
        
        // Column
        .company-column {
            display: flex;
            flex-direction: column;
            padding: 0;
            position: relative;
            left: 0;
            top: 0;
            height: 100%;
    
            .company-content {
                display: flex;
                flex-direction: column;
                border-radius: $border-radius;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                margin-bottom: initial;
                height: 100%;
            }
    
            .company-logo {
                min-height: 100px;
                display: flex;

                // Logo
                img {
                    margin-top:auto;
                    width: auto;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    padding: map-get($spacers, 3) map-get($spacers, 3);
                    border-radius: 3px;
                    max-height: 80px;
                }
            }

            &:nth-child(2) .company-logo img {
                max-height: 95px;
            }
            &:nth-child(3) .company-logo img {
                max-height: 95px;
            }
    
        }
    
        // Content body
        .content-body {
            padding: map-get($spacers, 5) 10%;
    
            .content-box {
                padding: 2rem;
                border-radius: $border-radius;
            }
    
            h2 {
                font-size: 1.4rem;
                font-weight: 700;
            }
            p {
                font-size: 1.2rem;
                color: #555;
            }
    
            // Supplier logos
            .logo-supplier {
                max-width: 170px;
            }
            /* Supplier logos */
            .logo-standox {
                width: 70px;
            }
            .logo-spieshecker {
                width: 50px;
            }
            .logo-mipa {
                width: 85px;
            }
            .logo-iiyama {
                width: 80px;
            }
            .logo-microsoft {
                width: 110px;
            }
        }        
    }
    
    
    /**
     * :: Buttons
     */
     .content-body a.btn {
        font-size: 1.2rem;
    }

    /* BG Wave */
    .company-column .content-body:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url('../images/wave.png');
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.07;
    }
    .company-column.column-alfa .content-body:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url('../images/wave2.png');
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.07;
    }
    
    }