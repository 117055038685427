body,html {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    overflow: hidden;
}

body {
    overflow: hidden;

    &.initPage {
        overflow: visible;
    }
}

.page-top {
    height: 100%;
    transition: all 4s;

    // ALS SVG
    #schweif-mask-rect {
        width: 100%;
        height: 0%;
        opacity: 0;
    }
    #als-schweif {
        opacity: 1;
    }

    // ALS Wrapper
    #als-wrapper {
        position: relative;
        height: 100%;
        align-items: center;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform:translate(-50%, -50%);
            width: 100%;

            @include media-breakpoint-only(sm) {
                height: 100%;
            }
        }

    }

    // initPage
    &.initPage {
        height: 22%;
    }

}

.page-bottom {
    position: relative;
    opacity: 0;
    transition: all 2s;

    // initPage
    &.initPage {
        opacity: 1;
        transition: all 2s;
        height: 78%;
    }
    
    // Row
    .company-row {
        position: relative;
        height: 100%;
    }

    // Column
    .company-column {
        position: absolute;
        top: 0;
        left: 0;
        height: 33.333333%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: map-get($spacers, 3);

        @include media-breakpoint-down(md) {
            &:nth-child(2) {
                top: 33.333333%;
            }
            &:nth-child(3) {
                top: 66.666666%;
            }
        }

        .company-content {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: $border-radius;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
        }

        .company-logo {
            // Logo
            img {
                width: auto;
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                padding: map-get($spacers, 3) map-get($spacers, 3);
                border-radius: 3px;
            }
        }

        // Alfa
        @include media-breakpoint-down(md) {
            &.column-alfa {
                background: linear-gradient(328deg, #442683, #da3c3b);
                background-size: 400% 400%;

                -webkit-animation: gradient-alfa 24s ease infinite;
                -moz-animation: gradient-alfa 24s ease infinite;
                animation: gradient-alfa 24s ease infinite;
            }

            // Burmeister
            &.column-burmeister {
                background: linear-gradient(328deg, #eb6309, #ffb220);
                background-size: 400% 400%;

                -webkit-animation: gradient-burmeister 15s ease infinite;
                -moz-animation: gradient-burmeister 15s ease infinite;
                animation: gradient-burmeister 15s ease infinite;
            }

            // SMS
            &.column-sms {
                background: linear-gradient(328deg, #04125b, #4494be);
                background-size: 400% 400%;

                -webkit-animation: gradient-sms 18s ease infinite;
                -moz-animation: gradient-sms 18s ease infinite;
                animation: gradient-sms 18s ease infinite;
            }
        }

    }

    // Content body
    .content-body {
        opacity: 0;
        transition: 0.6s all;
        height: 0;

        .content-box {
            padding: 0 map-get($spacers, 4) map-get($spacers, 4);
            background-color: #fff;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }

        h2 {
            font-size: 1.2rem;
            font-weight: 700;
        }
        p {
            font-size: 0.9rem;
            color: #555;
        }

        // Supplier logos
        .logo-supplier {
            max-width: 170px;
        }
        /* Supplier logos */
        .logo-standox {
            width: 80px;
        }
        .logo-spieshecker {
            width: 45px;
        }
        .logo-mipa {
            width: 90px;
        }
        .logo-sata {
            width: 70px;
        }
        .logo-iiyama {
            width: 90px;
        }
        .logo-microsoft {
            width: 130px;
        }
    }

    // Content bottom
    .content-bottom,
    .content-links {
        height: 0px;
        opacity: 0;
        width: 100%;
    }

    .content-links {
        padding: map-get($spacers, 3) map-get($spacers, 2);
    }

    // Content links logo invert
    .content-links a.logo-invert {
        max-height: 100px;
        display: block;

        img {
            max-height: 40px;
            width: 100%;
        }
    }

    // Active column
    .company-row.active-child {

        @include media-breakpoint-down(sm) {
            .company-column:not(.active) {
                height: 0px;
                min-height: 0px;
                transition: all 0.6s;
                overflow: hidden;
                opacity: 0;
                padding:0;
            }
            .company-column.active {
                min-height: 100%;
                height: auto;
                opacity: 1;
                align-items: flex-start;
                justify-content: flex-start;
                transition: all 1s;
                top: 0;

                .content-body {
                    transition: 3s all;
                    opacity: 1;
                    height: auto;

                    @include media-breakpoint-only(sm) {
                        margin-top: map-get($spacers, 3);
                    }
                }

                .company-content {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .content-bottom {
                    transition: 1s all;
                    opacity: 1;
                    height: auto;
                    margin-top: map-get($spacers, 3);
                    width: 100%;

                    a.btn {
                        color: #fff;
                        width: 100%;
                    }
                }

                .content-links {
                    transition: 3s all;
                    opacity: 1;
                    height: auto;
                    margin-top: auto;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        
    }

    
}
/**
 * ==================================================
 * :: Page top links
 * ==================================================
 */
 .page-top-links {
    position:absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    color: #838383;
    font-size: 0.6rem;
}
.page-top-links a {
    color: #838383;
    font-size: 0.6rem;
    padding:0 0.3rem;
    text-decoration: none;
    transition: 0.2s all;
}
.page-top-links a:hover {
    color: #333;
    text-decoration: underline;
    transition: 0.2s all;
}

/**
 * :: Buttons
 */
 .content-bottom a.btn {
    font-weight: 700;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    color: #fff;
    padding: 0.7rem 1.5rem 0.7rem 2rem;
    transition: all 0.2s;
    display: block;
    border-radius: 3px;
    box-shadow: 0px 4px 9px -6px #000;
}
.content-bottom a.btn:after {
    content: "\f105";
    font-family: "FontAwesome";
    padding-left: 0.5rem;
    transition: all 0.2s;
}
.content-bottom a.btn:hover {
    padding: 0.7rem 1.2rem 0.7rem 2rem;
    transition: all 0.2s;
}
.content-bottom a.btn:hover:after {
    padding-left: 0.8rem;
    transition: all 0.2s;
}

/* Button: Burmeister */
a.btn.btn-burmeister {
    background: #0063af;
    border: 1px solid #003f8b;
    border-bottom-width: 4px;
}
a.btn.btn-burmeister:hover {
    background: #01579e;
}

/* Button: Alfa */
a.btn.btn-alfa {
    background: #E14342;
    border: 1px solid #b61817;
    border-bottom-width: 4px;
}
a.btn.btn-alfa:hover {
    background: #D13332;
}

/* Button: SMS */
a.btn.btn-sms {
    background: #303a84;
    border: 1px solid #000a54;
    border-bottom-width: 4px;
}
a.btn.btn-sms:hover {
    background: #28327c;
}

/* Headlines */
.column-burmeister h2 {
    color: #0063af;
}
.column-alfa h2 {
    color: #DA3C3B;
}
.column-sms h2 {
    color: #000a54;
}