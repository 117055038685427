// Breakpoint 
@include media-breakpoint-up(md) {
.page-top {

    // ALS Wrapper
    #als-wrapper {
        position: relative;
        height: 100%;
        align-items: center;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform:translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }
}

.page-bottom {
    
    // Row
    .company-row {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    // Column
    .company-column {
        display: flex;
        flex-direction: column;
        padding: 0;
        position: relative;
        left: 0;
        top: 0;
        height: 100%;

        .company-content {
            display: flex;
            flex-direction: column;
            border-radius: $border-radius;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            margin-bottom: initial;
            height: 100%;
        }

        .company-logo {
            // Logo
            img {
                width: auto;
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                padding: map-get($spacers, 3) map-get($spacers, 3);
                border-radius: 3px;
                max-height: 75px;
            }
        }

        // Backgrounds
        &.column-alfa .content-body {
            background: linear-gradient(328deg, #442683, #da3c3b);
            background-size: 400% 400%;

            -webkit-animation: gradient-alfa 24s ease infinite;
            -moz-animation: gradient-alfa 24s ease infinite;
            animation: gradient-alfa 24s ease infinite;
        }

        // Burmeister
        &.column-burmeister .content-body {
            background: linear-gradient(328deg, #eb6309, #ffb220);
            background-size: 400% 400%;

            -webkit-animation: gradient-burmeister 15s ease infinite;
            -moz-animation: gradient-burmeister 15s ease infinite;
            animation: gradient-burmeister 15s ease infinite;
        }

        // SMS
        &.column-sms .content-body {
            background: linear-gradient(328deg, #04125b, #4494be);
            background-size: 400% 400%;

            -webkit-animation: gradient-sms 18s ease infinite;
            -moz-animation: gradient-sms 18s ease infinite;
            animation: gradient-sms 18s ease infinite;
        }

    }

    // Content body
    .content-body {
        opacity: 1;
        transition: 0.6s all;
        height: 100%;
        padding: map-get($spacers, 4);
        z-index: 1;

        .content-box {
            padding: map-get($spacers, 3);
            border-radius: $border-radius;
        }

        h2 {
            font-size: 1.2rem;
            font-weight: 700;
        }
        p {
            font-size: 0.9rem;
            color: #555;
        }

        // Supplier logos
        .logo-supplier {
            max-width: 170px;
        }
        /* Supplier logos */
        .logo-standox {
            width: 50px;
        }
        .logo-spieshecker {
            width: 30px;
        }
        .logo-mipa {
            width: 55px;
        }
        .logo-sata {
            width: 70px;
        }
        .logo-iiyama {
            width: 60px;
        }
        .logo-microsoft {
            width: 80px;
        }
    }

    // Content bottom
    .content-bottom,
    .content-links {
        height: auto;
        opacity: 1;
        width: 100%;
    }
    
}
/**
 * ==================================================
 * :: Page top links
 * ==================================================
 */
 .page-top-links {
    position:absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    color: #838383;
    font-size: 0.6rem;
}
.page-top-links a {
    color: #838383;
    font-size: 0.6rem;
    padding:0 0.3rem;
    text-decoration: none;
    transition: 0.2s all;
}
.page-top-links a:hover {
    color: #333;
    text-decoration: underline;
    transition: 0.2s all;
}

/**
 * :: Buttons
 */
 .content-body a.btn {
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.5px;
    color: #fff;
    padding: 0.6rem 1.5rem 0.6rem 2rem;
    transition: all 0.2s;
    display: block;
    border-radius: 3px;
    box-shadow: 0px 4px 9px -6px #000;
}
.content-body a.btn:after {
    content: "\f105";
    font-family: "FontAwesome";
    padding-left: 0.5rem;
    transition: all 0.2s;
}
.content-body a.btn:hover {
    padding: 0.6rem 1.2rem 0.6rem 2rem;
    transition: all 0.2s;
}
.content-body a.btn:hover:after {
    padding-left: 0.8rem;
    transition: all 0.2s;
}

/* Button: Burmeister */
a.btn.btn-burmeister {
    background: #0063af;
    border: 1px solid #003f8b;
    border-bottom-width: 4px;
}
a.btn.btn-burmeister:hover {
    background: #01579e;
}

/* Button: Alfa */
a.btn.btn-alfa {
    background: #E14342;
    border: 1px solid #b61817;
    border-bottom-width: 4px;
}
a.btn.btn-alfa:hover {
    background: #D13332;
}

/* Button: SMS */
a.btn.btn-sms {
    background: #303a84;
    border: 1px solid #000a54;
    border-bottom-width: 4px;
}
a.btn.btn-sms:hover {
    background: #28327c;
}

/* BG Wave */
.company-column .content-body:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/wave.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;
}
.company-column.column-alfa .content-body:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/wave2.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;
}

}