/**
 * ==================================================
 * :: Animations
 * ==================================================
 */
 @keyframes slideInFade {
    0% {
        transform: translateX(10%);
        opacity: 0;
    }
    70% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
@-webkit-keyframes gradient-burmeister {
    0%{background-position:0% 66%}
    50%{background-position:100% 35%}
    100%{background-position:0% 66%}
}
@-moz-keyframes gradient-burmeister {
    0%{background-position:0% 66%}
    50%{background-position:100% 35%}
    100%{background-position:0% 66%}
}
@keyframes gradient-burmeister {
    0%{background-position:0% 66%}
    50%{background-position:100% 35%}
    100%{background-position:0% 66%}
}
@-webkit-keyframes gradient-alfa {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}
@-moz-keyframes gradient-alfa {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}
@keyframes gradient-alfa {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}
@-webkit-keyframes gradient-sms {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}
@-moz-keyframes gradient-sms {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}
@keyframes gradient-sms {
    0%{background-position:14% 0%}
    50%{background-position:87% 100%}
    100%{background-position:14% 0%}
}